<template>
	<div class="h-full flex flex-col">
		<TopBar :showHome="true" :showChat="true"/>
		<ProgressBar :current="1"/>
		<AppTitle :title="tableName" v-if="table"/>
		<div class="pt-2 text-gray-400 flex-grow overflow-scroll" v-if="table && isHost">
			<p>Search and add friends to this table</p>

			<div class="mt-6 relative">
				<div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
					<font-awesome-icon icon="fa-solid fa-magnifying-glass" class="pl-2 text-gray-400"/>
				</div>
				<input type="text" v-model="keywords" class="block w-full pl-12 py-5 pr-4 border border-gray-100 placeholder-gray-400 text-gray-500 focus:ring-red-500 focus:border-red-500" :class="{
					'rounded-3xl': !active_search,
					'rounded-t-3xl': active_search
				}" placeholder="Search by name..." @input="debounceSearch">
				<div class="bg-white rounded-b-3xl absolute w-full" v-show="active_search">
					<div 
						class="flex bg-white border border-gray-100 border-t-0 py-4 px-4 items-center cursor-pointer text-gray-600"
						:class='index+1===search_results.length ? "rounded-b-3xl" : ""'
						v-for="(row, index) in search_results" 
						:key="row.uuid"
						@click.stop.prevent="inviteToTable(row)"
					>
						<user-profile-picture 
							:user="row"
							:size="8"
						/>
						<p class="font-bold flex-1 text-sm ml-3">{{ row.name }}</p>
					</div>
				</div>
			</div>

			<table-friends-row 
				v-for="friend in attendants" 
				:key="friend.uuid"
				:friend="friend"
			/>
		</div>
		<div class="pt-2 text-gray-400 flex-grow overflow-scroll" v-if="table && !isHost">
			<div class="flex items-center">
				<p class="flex-1 text-gray-600 font-bold text-lg mt-4">
					Attendees
				</p>
			</div>
			<div>
				<table-friends-row 
					:friend="user"
					:size="10"
					v-for="user in table.users"
					:key="user.uuid"
					:guest="true"
				/>
			</div>
		</div>
		<div class="w-full py-10 bg-gray-50">
			<div>
				<button class="bg-red-500 text-white py-2 rounded-xl w-full font-gothic text-lg" @click="next">
					Next
				</button>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import TopBar from '@/components/TopBar.vue';
	import ProgressBar from '@/components/ProgressBar.vue';
	import AppTitle from '@/components/AppTitle.vue';
	import UserProfilePicture from '@/components/UserProfilePicture';
	import TableFriendsRow from '@/components/TableFriendsRow';
	export default {
		components: {
			TopBar,
			AppTitle,
			ProgressBar,
			UserProfilePicture,
			TableFriendsRow,
		},
		data(){
			return {
				keywords: '',
				active_search: false,
				search_results: [],
				typing: null,
				debounce: null,
			}
		},
		watch: {
			keywords(value){
				if (value===''){
					this.active_search = false
					this.search_results = []
				}
			},
		},
		mounted(){
			if (this.answers.status === 'invited' && this.$route.name !== 'table-invite'){
				this.$router.push({name: 'table-invite'});
				return;
			}
		},
		computed: {
			isHost(){
				return this.table.user_id === this.$store.state.auth.user.id;
			},
			tableName(){
				return this.table.name.length ? this.table.name : `Table ${this.table.code.toUpperCase()}`
			},
			currentUser(){
				return this.$store.state.auth.user;
			},
			table(){
				return this.$store.state.table.table;
			},
			answers(){
				return this.table.answers;
			},
			attendants(){
				return this.table.users;
			}
		},
		methods: {
			debounceSearch(event) {
				this.typing = true
				clearTimeout(this.debounce)
				this.debounce = setTimeout(() => {
					this.typing = false
					this.keywords = event.target.value
					if (this.keywords.length){
						this.active_search = true;
						this.$store.dispatch('friends/searchFriends', this.keywords).then( r => {
							this.search_results = r.data.slice(0,6);
						});
					}
				}, 600)
			},
			next(){
				this.$store.dispatch('table/saveAnswers', {
					step: 2
				});
			},
			inviteToTable(user){
				this.$store.dispatch('table/inviteToTable', {
					user: user.uuid,
					table: this.$store.state.table.table.uuid
				}).then( () => {
					this.keywords = "";
				});
			},
		}
	}
</script>